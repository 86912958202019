<template>
    <div class="main">
        <content-container id="banner" class="banner p-d-flex p-jc-center p-align-center">
            <div class="banner-box p-d-flex p-jc-center">
                <img id="banner-man" src="/images/man-banner.svg" alt="banner man image">
                <h1 class="greet-word text-white p-text-center">Welcome to IMlab</h1>
            </div>
        </content-container>
        <content-container id="intro">
            <div class="intro-row p-grid p-align-center p-jc-center">
                <img id="img1" class="p-col-6 p-md-3" src="/images/man-intro1.svg" alt="computer man">
                <div id="content1" class="p-col-9 p-md-8 p-pl-0 p-pl-md-6">
                    <h3 class="p-mt-0 p-mb-3 p-mt-3 p-mt-md-0 text-white p-text-center p-text-md-left">Research Interests</h3>
                    <p class="p-m-0 text-white">
                        IM Lab is dedicated to understanding how research results in the fields of Artificial Intelligence and Multimedia can be applied to the storage, processing, transmission, and presentation of innovative intelligent media.
                    </p>
                </div>
            </div>
            <div class="intro-row p-grid p-align-center p-jc-center p-flex-column-reverse p-flex-md-row">
                <div id="content2" class="p-col-9 p-md-7 p-pr-0 p-pr-md-6">
                    <h3 class="p-mt-0 p-mb-3 p-mt-3 p-mt-md-0 text-white p-text-center p-text-md-left">Various Application</h3>
                    <p class="p-m-0 text-white">
                        Research carried out at IMLab extends broadly among the fields of Robotics, Artificial Intelligence, Motion Planning, Virtual Reality, Computer Graphics, Computer Animations, Image Processing, Computational Geometry, e-Learning, and Distributed Systems, to develop innovative tools and techniques.
                    </p>
                </div>
                <img id="img2" class="p-col-7 p-md-4" src="/images/man-intro2.svg" alt="puppet man">
            </div>
            <div class="intro-row p-grid p-align-center p-jc-center">
                <img id="img3" class="p-col-7 p-md-4" src="/images/man-intro3.svg" alt="meeting man">
                <div id="content3" class="p-col-9 p-md-7 p-pl-0 p-pl-md-6">
                    <h3 class="p-mt-0 p-mb-3 p-mt-3 p-mt-md-0 text-white p-text-center p-text-md-left">Regular Meeting</h3>
                    <p class="p-m-0 text-white">IMlab holds one regular group meeting and one small group meeting in a week. In the group meeting, student assigned to the week should share one paper related to his/her interest with others; In the small group meeting, students could discuss their own research with Prof. Tsai.</p>
                </div>
            </div>
        </content-container>
        <content-container id="news">
            <h2 class="p-text-center text-white">NEWS</h2>
            <news-row
                v-for="(item, index) in newsItems"
                :key="`news-${index}`"
                :id="item.id"
                :date="item.date"
                :title="item.title"
                date-color="second-l"
                title-color="white"
                border-color="white"
            ></news-row>
            <div class="p-d-flex p-jc-center p-pt-6">
                <custom-button
                    text="MORE NEWS"
                    bg-color="#4878b0"
                    border-color="white"
                    @click="goTo('/news')">
                </custom-button>
            </div>
        </content-container>
        <content-container id="project">
            <h2 class="p-text-center text-white">PROJECT</h2>
            <div class="p-grid">
                <div
                    v-for="(item, index) in projectItems"
                     :key="`project-${index}`"
                    class="project-item p-col-12 p-sm-6 p-lg-4 p-d-flex p-flex-column p-flex-sm-row p-jc-center p-align-center"
                >
                    <project-card
                        :tags="item.categories"
                        :title="item.title"
                        :authors="item.authors"
                        :abstract="breakWord(item.abstract)"
                        class="p-m-2"
                        @button-click="goTo(`/project/${item.id}`)"
                    ></project-card>
                </div>
            </div>
            <div class="p-d-flex p-jc-center p-pt-6">
                <custom-button
                    text="MORE PROJECT"
                    bg-color="#4878b0"
                    border-color="white"
                    @click="goTo('/project')">
                </custom-button>
            </div>
        </content-container>
        <content-container id="publication">
            <h2 class="p-text-center text-white">PUBLICATION</h2>
            <publication-row
                v-for="(item, index) in limit(5)"
                :key="`pub-${index}`"
                :author="item.pubAuthor"
                :conferenceLocation="item.pubCLoc"
                :conferenceName="item.pubCName"
                :publicationId="item.pubId"
                :language="item.pubLang"
                :year="item.pubYear"
                :month="item.pubMonth"
                :title="item.pubTitle"
                :type="item.pubType"
                :url="item.url"
                class="p-my-3"
                year-color="main"
                year-bg-color="white"
                text-color="white"
                border-color="white"
            ></publication-row>
            <div class="p-d-flex p-jc-center p-pt-6">
                <custom-button
                    text="MORE PUBLICATION"
                    bg-color="#4878b0"
                    border-color="white"
                    @click="goTo('/publication')">
                </custom-button>
            </div>
        </content-container>
        <custom-footer></custom-footer>
    </div>
</template>

<script>
    import { ref, onMounted } from "vue";
    import { useRouter } from "vue-router";
    import ContentContainer from "@/components/common/ContentContainer";
    import CustomButton from "@/components/common/CustomButton";
    import NewsRow from "@/components/news/NewsRow";
    import ProjectCard from "@/components/project/ProjectCard";
    import PublicationRow from "@/components/publication/PublicationRow";
    import CustomFooter from "@/components/common/CustomFooter";
    import { breakWord } from "@/utils/word";
    import { apiExecutor } from "@/api";
    import { gsap, Back } from "gsap";
    import { ScrollTrigger } from "gsap/ScrollTrigger";
    gsap.registerPlugin(ScrollTrigger);


    export default {
        name: 'Home',
        components: { ContentContainer, CustomButton, NewsRow, ProjectCard, PublicationRow, CustomFooter },
        setup() {
            let newsItems = ref([]);
            let projectItems = ref([]);
            let publicationItems = ref([]);
            const router = useRouter();
            const setAnimation = async function() {
                const timeline0 = new gsap.timeline({
                    scrollTrigger: {
                        trigger: "#banner",
                        start: "-200",
                        end: "+=100",
                    }
                });

                await timeline0
                    .from("#banner-man",{ opacity: 0, y: -500, duration: 1 })
                    .from(".greet-word", { opacity: 0, y: 50, duration: 1 });


                const timeline1 = new gsap.timeline({
                    scrollTrigger: {
                        trigger: "#intro",
                        start: "-500",
                        end: "+=1000",
                    }
                });

                await timeline1
                    .from("#img1", { delay: 0.25, scale: 0, duration: 0.5 })
                    .from("#content1", { opacity: 0, duration: 0.5 })
                    .from("#img2", { scale: 0, duration: 0.5 })
                    .from("#content2", { delay: 0.25, opacity: 0, duration: 0.5 })
                    .from("#img3", { scale: 0, duration: 0.5 })
                    .from("#content3", { delay: 0.25, opacity: 0, duration: 0.5 });

                const timeline2 = new gsap.timeline({
                    scrollTrigger: {
                        trigger: "#news",
                        start: "-150",
                        end: "+=100",
                    }
                });

                await timeline2.staggerFrom(".news-row", 1, { opacity:0, x: -500, ease: Back.easeInOut }, 0.25);

                const timeline3 = new gsap.timeline({
                    scrollTrigger: {
                        trigger: "#project",
                        start: "-60",
                        end: "+=100",
                    }
                });

                await timeline3.staggerFrom(".project-item", 1, { opacity:0, x: -400 }, 0.1);

                const timeline4 = new gsap.timeline({
                    scrollTrigger: {
                        trigger: "#publication",
                        start: "-40",
                        end: "+=100",
                    }
                });

                await timeline4.staggerFrom(".publication-row", 1.5, { opacity:0, x: -500, ease: Back.easeInOut }, 0.25);
            };

            onMounted(async () => {
                newsItems.value = await apiExecutor.getPartNewsList({ index: 5 });
                projectItems.value = await apiExecutor.getPartIMProjectList({ index: 3 });
                publicationItems.value = await apiExecutor.getPublicationList();
                await setAnimation();
            });

            const goTo = function (path) {
                router.push(path);
            };

            const limit = function(num) {
                return publicationItems.value.filter((item,index)=>{return index < num});
            }

            return { newsItems, projectItems, publicationItems, breakWord, goTo, limit };
        }
    }
</script>

<style scoped lang="scss">
    h2 {
        font-size: 48px;
    }

    .banner {
        position: relative;
        min-height: 100vh;

        .banner-box {
            width: 200%;
            max-width: 700px;

            img {
                width: 500%;
                max-width: 500px;
            }

            h1 {
                font-size: 64px;
                position: absolute;
                bottom: 35%;
            }
        }
    }

    #intro {
        p {
            letter-spacing: 1.6px;
            line-height: 20px;
        }

        h3 {
            font-size: 24px;
        }

        .intro-row {
            padding: 10% 0;
        }
    }
</style>